
    .createStepOne{
        height: 100%;
        padding: 30px;
        .createStepOne-form{
            /*padding-left: 300px*/
        }
        .createStepBottom{
            margin-top: 100px;
            /*margin-top: 30px;*/
            /*margin-bottom: 30px;*/
            display: flex;
            /*justify-content: center;*/
            margin-left: 30.6%;
            .last-step{
                width: 100px;
                background: #ffffff;
                color: #1122D8;
                border-color:#1122D8 ;
            }
            .next-step{
                width: 100px;
                background: #1122D8;
                color: #ffffff;
                border-color: #1122D8;
                margin-left: 20px;
            }
        }
    }
